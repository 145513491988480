import { Flex, Divider, Heading, Text } from '@chakra-ui/react'
import { theme } from 'src/themes'

const Bloc = ({ title, text }: { title: string; text: string }) => {
  return (
    <Flex direction="column" w="full">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        w="full"
        alignItems={{ base: 'flex-start', md: 'center' }}
        justifyContent="space-between"
        rowGap={6}
      >
        <Heading size="2xl" color="dark_green">
          {title}
        </Heading>
        <Text dangerouslySetInnerHTML={{ __html: text }} maxW={{ base: '100%', md: '400px', lg: '600px' }} size="lg" />
      </Flex>
    </Flex>
  )
}

const History = () => {
  return (
    <Flex
      w="full"
      direction="column"
      border="0.5px solid"
      borderColor="border"
      borderRadius="4px"
      rowGap={10}
      alignItems="center"
      p={12}
      bg="white"
    >
      <Flex w="full" direction="column" rowGap={12}>
        <Bloc
          title={'Hier,'}
          text={`Oser lancer la première plateforme de financement dédiée à la <span style="color:${theme.colors.green}">transition agricole et alimentaire</span>, thématique au cœur des enjeux de notre siècle.`}
        />
        <Divider />
        <Bloc
          title={"Aujourd'hui,"}
          text={`Avoir investi <span style="color:${theme.colors.green}">150 millions</span> d’euros dans <span style="color:${theme.colors.green}">7 000</span> projets régénératifs et pérennisé <span style="color:${theme.colors.green}">35 000</span> emplois grâce à notre communauté de <span style="color:${theme.colors.green}">500 000</span> membres.`}
        />
        <Divider />
        <Bloc
          title={'Demain,'}
          text={`Agir à plus grande échelle pour l'agriculture, l’alimentation, le climat et l’environnement pour notre <span style="color:${theme.colors.green}">avenir commun et celui des générations futures</span>.`}
        />
      </Flex>
    </Flex>
  )
}

export default History
