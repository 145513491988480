import {
  Flex,
  Heading,
  Text,
  Button,
  useBreakpointValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Container,
  Image,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useState } from 'react'

import { Carousel, usePlatform } from '@miimosa/design-system'

const data = [
  {
    name: 'Energies\nRenouvelables',
    text: 'Investir dans la transition énergétique tout en contribuant à diversifier les revenus des agriculteurs.',
    link: '/projects?type=lending&thematic=enr',
    imgName: 'enr.jpg',
    translate: '50px',
  },
  {
    name: 'Coopératives et\nentreprises',
    text: 'Investir dans des <strong>coopératives</strong>, PME et ETI (10 à 5 000 salariés) qui participent activement à notre souveraineté alimentaire.',
    link: '/projects?type=lending&thematic=coop',
    imgName: 'cooperative.jpg',
    translate: '50px',
  },
  {
    name: 'Exploitations et artisanat',
    text: 'Participer au renouvellement des générations et à la transition vers une agriculture régénératrice et une alimentation durable.',
    link: '/projects?type=lending&thematic=craft',
    imgName: 'exploitation.jpg',
    translate: '50px',
  },
  {
    name: 'Startups',
    text: 'Financer le développement d’entreprises innovantes en forte croissance apportant des réponses aux enjeux de notre siècle.',
    link: '/projects?type=lending&thematic=startup',
    imgName: 'startup.jpg',
    translate: '50px',
  },
]

const data2 = [
  {
    name: 'Notre communauté',
    text: 'En soutenant en don ou en investissant, 500 000 membres nous ont déjà rejoints pour agir concrètement pour demain, faisant de MiiMOSA la plus grande communauté engagée de la thématique en Europe !',
    imgName: 'community.jpg',
  },
  {
    name: 'Fonds de dette\nprivée',
    text: 'Notre premier fonds de dette privée, doté de 50 millions d’euros, co-finance des projets aux côtés de nos membres. Lancé en 2022 auprès d’investisseurs institutionnels, il marque le début d’une nouvelle étape dans l’expansion de MiiMOSA et de sa mission.',
    imgName: 'debt.jpg',
    link: 'https://blog.miimosa.com/le-premier-fonds-de-dette-dedie-a-la-thematique-agricole',
  },
  {
    name: 'Les marques engagées',
    text: 'A nos côtés, déjà plus de 40 programmes d’engagement ont permis aux marques et entreprises de participer à la transition de leurs filières vers une agriculture régénératrice et à la réduction de leur empreinte carbone (scope 3).',
    link: '/partners',
    imgName: 'engaged_company.jpg',
  },
]

const renderBox = ({ name, link, imgName }: { name: string; link?: string; imgName: string }) => {
  return (
    <Flex w="full" direction="column" px={1}>
      <Flex w="100%" h="380px" position="relative" bg="#0000003d" borderRadius={'4px'}>
        <Image
          src={`images/home/${imgName}`}
          alt=""
          objectFit="cover"
          w="full"
          h="full"
          position="absolute"
          zIndex="-1"
        />
        <Flex
          direction="column"
          w="full"
          h="full"
          alignItems="flex-start"
          justifyContent="flex-end"
          zIndex={1}
          rowGap={6}
          p={10}
        >
          <Heading size="md" color="white">
            {name}
          </Heading>
          {link && (
            <NextLink href={link}>
              <Button variant="secondaryW" size="md" width="fit-content">
                Découvrir
              </Button>
            </NextLink>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

const Engagements = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const { platform } = usePlatform()

  return isMobile ? (
    <Flex w="full" direction="column" rowGap={8} pt={8}>
      <Heading as="h1" size="2xl" whiteSpace="pre-wrap" textAlign="center">
        {"L'engagement\n en action"}
      </Heading>
      <Accordion allowMultiple={true} defaultIndex={[0, 1]}>
        {platform == 'fr' && (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Flex direction="row" w="full" justifyContent="space-between" alignItems="center" py={2}>
                    <Heading size="sm" fontWeight="400">
                      Les thématiques de la transition
                    </Heading>
                    {isExpanded ? <Text>-</Text> : <Text>+</Text>}
                  </Flex>
                </AccordionButton>
                <AccordionPanel px={0}>
                  <Carousel
                    isDark
                    draggable
                    navBottom={false}
                    centerMode
                    centerPadding={'20px'}
                    boxedControls={false}
                    data={data}
                    showControls={false}
                    SlideComponent={renderBox}
                    itemsPerPage={1}
                    slidesToScroll={1}
                    infinite={false}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )}
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                <Flex direction="row" w="full" justifyContent="space-between" alignItems="center" py={2} mb={4}>
                  <Heading size="sm" fontWeight="400">
                    Les acteurs de la transition
                  </Heading>
                  {isExpanded ? <Text>-</Text> : <Text>+</Text>}
                </Flex>
              </AccordionButton>
              <AccordionPanel px={0}>
                <Carousel
                  isDark
                  draggable
                  navBottom={false}
                  centerMode
                  centerPadding={'20px'}
                  boxedControls={false}
                  data={data2}
                  showControls={false}
                  SlideComponent={renderBox}
                  itemsPerPage={1}
                  slidesToScroll={1}
                  infinite={false}
                />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Flex>
  ) : (
    <Flex w="full" bg="creamy" direction="column" alignItems="center" rowGap={16}>
      <Container variant="boxed" py={0} rowGap={8}>
        <Heading as="h1" size="2xl" whiteSpace="pre-wrap" textAlign="center">
          {"L'engagement\n en action"}
        </Heading>
        <Flex direction="column" w="full" rowGap={14}>
          {platform == 'fr' && (
            <Flex direction="column" w="full" rowGap={6}>
              <Heading size="lg">Les thématiques de la transition</Heading>
              <Flex w="full" direction="row" columnGap={3} justifyContent="space-between">
                {data.map(({ name, text, link, imgName, translate }, i) => (
                  <Bloc
                    key={`theme_${i}`}
                    title={name}
                    text={text}
                    link={link}
                    imgName={imgName}
                    bgColor="orange"
                    defaultWidth="300px"
                    translate={translate}
                  />
                ))}
              </Flex>
            </Flex>
          )}
          <Flex direction="column" w="full" rowGap={6}>
            <Heading size="lg">Les acteurs de la transition</Heading>
            <Flex w="full" direction="row" columnGap={8} mb={16}>
              {data2.map(({ name, text, link, imgName }, i) => (
                <Bloc
                  key={`theme_${i}`}
                  title={name}
                  text={text}
                  link={link}
                  imgName={imgName}
                  bgColor="green"
                  defaultWidth="100%"
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}

const Bloc = ({
  title,
  text,
  imgName,
  link,
  bgColor,
  defaultWidth,
  translate = '0px',
}: {
  title: string
  text: string
  imgName: string
  link?: string
  bgColor: string
  defaultWidth: string
  translate?: string
}) => {
  const [animationValues, setAnimationValues] = useState<{
    translate: string
    width: string
    visible: 'hidden' | 'visible'
    opacity: string
    imgOpacity: string
    blocBgColor: string
  }>({
    translate: '200px',
    width: defaultWidth,
    visible: 'hidden',
    opacity: '0',
    imgOpacity: '0.7',
    blocBgColor: 'black',
  })

  return (
    <Flex
      w={animationValues.width}
      h="380px"
      position="relative"
      direction="column"
      transition="all 1s ease"
      overflow="hidden"
      borderRadius={'4px'}
      onMouseEnter={() =>
        setAnimationValues({
          translate: translate,
          visible: 'visible',
          width: `calc(${animationValues.width} + 60px)`,
          opacity: '1',
          imgOpacity: '0',
          blocBgColor: bgColor,
        })
      }
      onMouseLeave={() =>
        setAnimationValues({
          translate: '200px',
          visible: 'hidden',
          width: defaultWidth,
          opacity: '0',
          imgOpacity: '0.7',
          blocBgColor: 'black',
        })
      }
      bg={animationValues.blocBgColor}
    >
      <Image
        src={`images/home/${imgName}`}
        alt=""
        objectFit="cover"
        w="full"
        h="full"
        position="absolute"
        opacity={animationValues.imgOpacity}
        transition="opacity 0.5s linear"
      />
      <Flex
        p={10}
        direction="column"
        zIndex={1}
        rowGap={6}
        transition={'all 1s ease'}
        transform={`translateY(${animationValues.translate})`}
        w="full"
      >
        <Heading size={{ base: 'md', xl: 'lg' }} whiteSpace="pre-wrap" color="white" w={`calc(${defaultWidth} - 40px)`}>
          {title}
        </Heading>

        <Text
          size="md"
          color="white"
          width={`calc(${defaultWidth} - 60px)`}
          opacity={animationValues.opacity}
          transition={'opacity 1s linear, visibility 0.5s linear'}
          visibility={animationValues.visible}
          dangerouslySetInnerHTML={{ __html: text }}
        />

        {link && (
          <NextLink href={link}>
            <Button
              variant="secondaryW"
              size="md"
              w="fit-content"
              transition={'opacity 1s linear, visibility 0.5s linear'}
              opacity={animationValues.opacity}
              visibility={animationValues.visible}
            >
              Découvrir
            </Button>
          </NextLink>
        )}
      </Flex>
    </Flex>
  )
}

export default Engagements
